var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"car-brand",attrs:{"id":"user-list"}},[_c('CarFleetAddNew',{attrs:{"id":_vm.carFleetId,"type-options":_vm.typeOptions,"brandOptions":_vm.brandOptions,"featureOptions":_vm.featureOptions,"fuelOptions":_vm.fuelOptions,"gearOptions":_vm.gearOptions},on:{"reset-id":function($event){_vm.carFleetId = null},"car-fleet-updated":_vm.carFleetUpdated},model:{value:(_vm.isAddNewCarBrandSidebarActive),callback:function ($$v) {_vm.isAddNewCarBrandSidebarActive=$$v},expression:"isAddNewCarBrandSidebarActive"}}),_c('v-card',[_c('v-card-title',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-auto"},[_vm._v(" Car Fleet ")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"text-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-btn',{staticClass:"mb-4",attrs:{"icon":""},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-1",attrs:{"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiFilter)+" ")])]}}])},[_c('span',[_vm._v("Filters")])])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-1",attrs:{"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiSort)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sort By")])])],1)]}}])},[_c('v-list',_vm._l((_vm.sortOptions),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.sortBy = item.value}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-btn',{staticClass:"mb-4",attrs:{"icon":""},on:{"click":function($event){return _vm.exportToExcel()}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-1",attrs:{"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])]}}])},[_c('span',[_vm._v("Download")])])],1)],1)]),(_vm.showFilters)?_c('v-card-text',{staticClass:"d-flex flex-wrap pb-0"},[_c('v-select',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Select Status","items":_vm.statusOptions,"item-text":"title","item-value":"value","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}}),_c('v-select',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Select Type","items":_vm.typeOptions,"item-text":"title","item-value":"value","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.typeFilter),callback:function ($$v) {_vm.typeFilter=$$v},expression:"typeFilter"}})],1):_vm._e(),_c('p',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.filteredCarFleet.length || 'No')+" records found")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"3"}},[_c('AddNewCard',{attrs:{"minHeight":"175","title":"Add Car Fleet"},on:{"click":_vm.addCarFleet}})],1),_vm._l((_vm.paginatedCarFleet[_vm.page - 1]),function(ref,c){
          var id = ref.id;
          var brand = ref.brand;
          var imgcarmob = ref.imgcarmob;
          var markimgcarmob = ref.markimgcarmob;
          var active = ref.active;
          var ltype = ref.ltype;
          var markltype = ref.markltype;
          var updatedon = ref.updatedon;
          var imgslider = ref.imgslider;
          var markimgslider = ref.markimgslider;
          var fleetClass = ref.class;
          var fuel = ref.fuel;
          var gear = ref.gear;
          var lugguage = ref.lugguage;
          var seat = ref.seat;
return _c('v-col',{key:c,attrs:{"cols":"12","lg":"4","xl":"3"}},[_c('CarFleetCard',{attrs:{"id":id,"brandName":brand,"image":_vm.country == 'UK' ? imgcarmob : markimgcarmob,"isActive":active,"type":_vm.country == 'UK' ? ltype : markltype,"sliderImages":_vm.getSliderImages(_vm.country == 'UK' ? imgslider : markimgslider),"updatedOn":_vm.formatDate(updatedon.toDate(), 'DD-MMM-YYYY hh:mm A'),"fleetClass":fleetClass,"fuel":fuel,"gear":gear,"luggage":lugguage,"seat":seat,"showNotification":id == _vm.lastUpdatedCarFleetId},on:{"edit-carfleet":_vm.editCarFleet}})],1)})],2),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mt-2 mr-auto ml-5"},[_c('span',[_vm._v("Items per page: "+_vm._s(_vm.itemsPerPage)+" ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiMenuDown)+" ")])]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageOptions),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.itemsPerPage = item.title}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.countText))]),_c('v-pagination',{attrs:{"length":_vm.paginatedCarFleet.length},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_vm._v(" ̉ ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }