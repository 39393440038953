<template>
  <v-card>
    <v-card-title class="d-flex">
      <div class="mr-auto">Car Info</div>
      <div>
        <v-btn color="primary" text v-if="!editMode" @click="editMode = true">Edit</v-btn>
        <v-btn color="primary" text v-if="editMode" @click="cancel()">Cancel</v-btn>
      </div>
    </v-card-title>
    <v-card-text v-if="editMode">
      <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
        <v-row>
          <v-col cols="12">
            <v-col cols="12" md="6">
              <v-textarea
                v-model="carFleetData.car_info"
                label="Car info *"
                outlined
                dense
                hide-details="auto"
                :rules="[required]"
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-btn color="primary" type="submit"> Submit </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text v-else> </v-card-text>
  </v-card>
</template>
<script>
import { ref } from '@vue/composition-api'
import { requiredLength, between } from '@core/utils/validation'
import { required } from '@core/utils/validation'
import useCarInfo from './useCarInfo'

export default {
  setup() {
    const { docId, carFleetData, fetchCarFleet, updateCarFleet } = useCarInfo()
    const editMode = ref(false)
    const currentTab = ref(0)

    const form = ref(null)
    const valid = ref(false)
    const validate = () => {
      form.value.validate()
    }
    const onSubmit = async () => {
      if (valid.value) {
        editMode.value = false
        await updateCarFleet()
      } else {
        validate()
      }
    }

    const types = ['Luxury', 'Sports Car', 'Executive', 'Super Car', 'Standard', 'Premium']
    const cancel = () => {
      editMode.value = false
      fetchCarFleet(docId)
    }
    return {
      validate,
      valid,
      form,
      types,
      editMode,
      // validation rules
      required,
      between,
      //Current tab
      currentTab,
      // Hooks
      carFleetData,
      cancel,
      onSubmit,
      validators: { requiredLength },
    }
  },
}
</script>