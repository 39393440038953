import useAppConfig from '@/@core/@app-config/useAppConfig'
import useCommon from '@/composables/useCommon'
import useFirestoreCollectionNames from '@/composables/useFirestoreCollectionNames'
import { ref, watch } from '@vue/composition-api'
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
  writeBatch,
} from 'firebase/firestore'
import { chunk, filter, isNil, orderBy as sortOrder } from 'lodash'
import useImageKit from './../../../composables/useImageKit'
import { db } from '/src/firebase/config'

export default function useCarBrandList() {
  const { stringContainsAny } = useCommon()
  const {
    carBrandCollectionName,
    carFleetCollectionName,
    selfDriveCarCollectionName,
    chauffeurDriveCarCollectionName,
  } = useFirestoreCollectionNames()
  const { country } = useAppConfig()

  const brandCollection = collection(db, carBrandCollectionName.value)
  let blankCarBrandData = {
    active: true,
    brandimg: null,
    description: '',
  }

  if (country.value == 'UK') {
    blankCarBrandData = {
      ...blankCarBrandData,
      ltype: '',
    }
  } else {
    blankCarBrandData = {
      ...blankCarBrandData,
      markltype: '',
    }
  }

  const carBrandData = ref(JSON.parse(JSON.stringify(blankCarBrandData)))

  const carBrands = ref([])
  const filteredCarBrands = ref([])
  const paginatedCarBrands = ref([])
  const error = ref(null)
  const page = ref(1)
  const itemsPerPage = ref(16)

  const searchQuery = ref('')
  const statusFilter = ref(true)
  const typeFilter = ref(null)
  const sortBy = ref(null)
  const loading = ref(false)
  let unsubscribe = null
  const options = ref({
    sortBy: ['updatedon'],
    sortDesc: [true],
  })

  const typeKey = country.value == 'UK' ? 'ltype' : 'markltype'

  const queryOptions = () => {
    const qOptions = []

    const orderByKey = options.value.sortBy[0] ? options.value.sortBy[0] : 'updatedon'
    const order = options.value.sortDesc[0] ? 'desc' : 'asc'
    qOptions.push(orderBy(orderByKey, order))

    return qOptions
  }

  // fetch data
  const fetchCarBrands = async () => {
    try {
      if (unsubscribe) {
        unsubscribe()
      }
      loading.value = true
      carBrands.value = []
      filteredCarBrands.value = []

      const q = query(brandCollection, ...queryOptions())
      unsubscribe = onSnapshot(q, querySnapshot => {
        carBrands.value = []
        querySnapshot.forEach(doc => {
          carBrands.value.push({
            id: doc.id,
            ...doc.data(),
          })
        })
        filteredCarBrands.value = carBrands.value
        filterCarBrands()
      })
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const checkIfCarBrandExist = async () => {
    const q = query(
      brandCollection,
      where('description', '==', carBrandData.value.description),
      where(typeKey, '==', carBrandData.value[typeKey]),
    )
    const querySnapshot = await getDocs(q)
    return !!querySnapshot.size
  }

  const addCarBrand = async () => {
    try {
      loading.value = true
      error.value = null
      if (!carBrandData.value.brandimg) {
        throw new Error('Please add brand logo')
      }
      if (await checkIfCarBrandExist()) {
        throw new Error('Car brand already exist!')
      }
      const { uploadFile } = useImageKit()
      const imageFile = carBrandData.value.brandimg
      const imageName = carBrandData.value.brandimg.name
      const tags = [carBrandData.value.description, carBrandData.value[typeKey]]
      const fileUrl = await uploadFile(imageFile, imageName, tags, `/${country.value}/`)
      const carBrand = {
        ...carBrandData.value,
        brandimg: fileUrl,
        createdon: serverTimestamp(),
        updatedon: serverTimestamp(),
      }
      await addDoc(brandCollection, carBrand)
    } catch (e) {
      error.value = e.message
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const editCarBrand = async id => {
    try {
      loading.value = true
      error.value = null
      const prevCarBrand = filter(carBrands.value, carBrand => carBrand.id == id)
      let carBrand = {
        ...carBrandData.value,
        updatedon: serverTimestamp(),
      }
      if (
        prevCarBrand.length &&
        (prevCarBrand[0].description != carBrand.description || prevCarBrand[0][typeKey] != carBrand[typeKey])
      ) {
        if (await checkIfCarBrandExist()) {
          throw new Error('Car brand already exist!')
        }
      }
      if (!carBrandData.value.brandimg) {
        throw new Error('Please add brand logo')
      } else if (carBrandData.value.brandimg instanceof File) {
        const { uploadFile } = useImageKit()
        const imageFile = carBrandData.value.brandimg
        const imageName = carBrandData.value.brandimg.name
        const tags = [carBrandData.value.description, carBrandData.value[typeKey]]
        const fileUrl = await uploadFile(imageFile, imageName, tags, `/${country.value}/`)
        carBrand = {
          ...carBrand,
          brandimg: fileUrl,
        }
      }
      await batchUpdate(id, prevCarBrand[0], carBrand)
      // const docReference = doc(db, carBrandCollectionName.value, id)
      // await updateDoc(docReference, carBrand)
    } catch (e) {
      error.value = e.message
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const batchUpdate = async (brandId, prevCarBrand, newCarBrand) => {
    const { description, active, brandimg } = newCarBrand
    const brandDocRef = doc(db, carBrandCollectionName.value, brandId)

    const carFleetCollection = carFleetCollectionName.value
    const queryCarFleet = query(
      collection(db, carFleetCollection),
      where('brand', '==', prevCarBrand.description),
      where(typeKey, '==', prevCarBrand[typeKey]),
    )

    const selfDriveCollection = selfDriveCarCollectionName.value
    const querySelfDrive = query(
      collection(db, selfDriveCollection),
      where('carbrand', '==', prevCarBrand.description),
      where(typeKey, '==', prevCarBrand[typeKey]),
    )

    const chauffeurDriveCollection = chauffeurDriveCarCollectionName.value
    const queryChauffeurDrive = query(
      collection(db, chauffeurDriveCollection),
      where('carbrand', '==', prevCarBrand.description),
      where(typeKey, '==', prevCarBrand[typeKey]),
    )
    const batch = writeBatch(db)

    // 1. Update brand
    batch.update(brandDocRef, newCarBrand)

    // 2. Update Car Fleet
    const updateObjCarFleet = {
      brand: description,
      brandimg,
      brandactive: active,
    }
    const carFleetItems = await getDocs(queryCarFleet)
    carFleetItems.forEach(document => {
      const docRef = doc(db, carFleetCollection, document.id)
      batch.update(docRef, updateObjCarFleet)
    })

    // 3. Update Self Drive
    const updateObjSelfDrive = {
      carbrand: description,
      brandimg,
      brandactive: active,
    }
    const selfDriveItems = await getDocs(querySelfDrive)
    selfDriveItems.forEach(document => {
      const docRef = doc(db, selfDriveCollection, document.id)
      batch.update(docRef, updateObjSelfDrive)
    })

    // 4. Update Chauffeur Drive
    const updateObjChauffeurDrive = {
      carbrand: description,
      brandimg,
      brandactive: active,
    }
    const chauffeurDriveItems = await getDocs(queryChauffeurDrive)
    chauffeurDriveItems.forEach(document => {
      const docRef = doc(db, chauffeurDriveCollection, document.id)
      batch.update(docRef, updateObjChauffeurDrive)
    })

    await batch.commit()
  }

  const filterCarBrands = () => {
    let filteredBrands = carBrands.value
    if (searchQuery.value && searchQuery.value.length) {
      filteredBrands = filter(filteredBrands, item => {
        return stringContainsAny(item.description + item[typeKey], searchQuery.value)
      })
    }

    if (!isNil(statusFilter.value)) {
      filteredBrands = filter(filteredBrands, ({ active }) => {
        return statusFilter.value == active
      })
    }

    if (typeFilter.value && typeFilter.value.length) {
      filteredBrands = filter(filteredBrands, item => {
        return typeFilter.value == item[typeKey]
      })
    }

    if (!isNil(sortBy.value)) {
      filteredBrands = sortOrder(filteredBrands, [sortBy.value.by], [sortBy.value.order])
    }

    filteredCarBrands.value = filteredBrands
  }

  watch([searchQuery, statusFilter, typeFilter, sortBy], () => {
    filterCarBrands()
  })

  watch([filteredCarBrands, itemsPerPage], () => {
    const chunkSize = itemsPerPage.value == 'All' ? filteredCarBrands.value.length : itemsPerPage.value
    paginatedCarBrands.value = chunk(filteredCarBrands.value, chunkSize)
    page.value = 1
  })

  const unsubscribeCarBrands = () => {
    unsubscribe()
  }

  fetchCarBrands()

  return {
    carBrands,
    page,
    itemsPerPage,
    filteredCarBrands,
    paginatedCarBrands,
    searchQuery,
    statusFilter,
    typeFilter,
    sortBy,
    loading,
    carBrandData,
    blankCarBrandData,
    error,

    unsubscribeCarBrands,
    addCarBrand,
    editCarBrand,
  }
}
