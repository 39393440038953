// import useMoment from './../../../../composables/useMoment'
import { ref } from '@vue/composition-api'
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '/src/firebase/config'

export default function useSecurityDeposit() {
  const carFleetData = ref(null);
  const docId = ref(null);

  // fetch data
  const fetchCarFleet = async (id) => {
 
    try {
        const q = doc(db, 'carfleet',`${id}`);
        const querySnapshot = await getDoc(q)
        if (querySnapshot.exists()) {
          carFleetData.value =  querySnapshot.data();
          carFleetData.value.faq_data =[]
          console.log(carFleetData.value,"********************8888")
        }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  const updateCarFleet = async (data) => {
   
      const docReference = doc(db, 'carFleet', `${docId}`)
      await updateDoc(docReference, {faq: data}).catch(err=>{
        console.log(err,"err")
      })
   
  }

  fetchCarFleet()

  return {
    carFleetData,
    fetchCarFleet,
    updateCarFleet,
    docId
  }
}
