<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary"> {{ id ? 'Update' : 'Add' }} Car Fleet</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-select
            v-model="carFleetData[typeKey]"
            :rules="[validators.required]"
            label="Car Type *"
            :items="typeOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="carTypeChanged"
          >
          </v-select>

          <v-select
            v-model="carFleetData.brand"
            :rules="[validators.required]"
            label="Brand *"
            :items="filterdBrandOptions"
            item-text="description"
            item-value="description"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="brandOptionChanged"
          >
          </v-select>

          <v-text-field
            v-model="carFleetData.class"
            outlined
            dense
            :rules="[validators.required]"
            label="Class *"
            placeholder="Class"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-select
            v-model="carFleetData.features"
            :rules="[validators.required]"
            label="Features *"
            :items="featureOptions"
            item-text="description"
            item-value="description"
            multiple
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="featureOptionChanged"
          >
          </v-select>

          <v-select
            v-model="carFleetData.fuel"
            :rules="[validators.required]"
            label="Fuel Type *"
            :items="fuelOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-select
            v-model="carFleetData.gear"
            :rules="[validators.required]"
            label="Gear *"
            :items="gearOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-text-field
            type="number"
            v-model.number="carFleetData.lugguage"
            outlined
            dense
            :rules="[validators.requiredLength]"
            label="Luggage *"
            placeholder="Luggage"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            type="number"
            v-model.number="carFleetData.seat"
            outlined
            dense
            :rules="[validators.requiredLength]"
            label="Seat *"
            placeholder="Seat"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-file-input
            accept="image/*"
            prepend-inner-icon="$file"
            prepend-icon=""
            label="App Landing Image"
            placeholder="App Landing Image"
            outlined
            dense
            @change="onLandingImageChange"
          ></v-file-input>

          <v-avatar tile size="80" v-if="carFleetData[imgcarmobKey]" class="mb-6">
            <v-img contain :src="imageUrl"></v-img>
          </v-avatar>

          <v-file-input
            accept="image/*"
            prepend-inner-icon="$file"
            prepend-icon=""
            label="Slider Images"
            placeholder="Slider Images"
            outlined
            dense
            multiple
            @change="onSliderImageChange"
            class="mb-6"
          ></v-file-input>

          <v-row>
            <v-col v-for="(imageUrl, i) in sliderImagesUrl" :key="i" cols="4">
              <v-avatar tile size="80">
                <v-img contain :src="imageUrl"></v-img>
                <v-icon small color="error" class="close-icon" @click="removeSliderImage(i)">{{
                  icons.mdiClose
                }}</v-icon>
              </v-avatar>
            </v-col>
          </v-row>
   
          <v-file-input
            accept="image/*"
            prepend-inner-icon="$file"
            prepend-icon=""
            label="Avatar Slider Images"
            placeholder="Avatar Slider Images"
            outlined
            dense
            multiple
            @change="onAvatarImageChange"
          ></v-file-input>

          <v-row>
            <v-col v-for="(imageUrl, i) in avatarImages" :key="i" cols="4">
              <v-avatar tile size="80">
               
                <v-img contain :src="imageUrl"></v-img>
                <v-icon small color="error" class="close-icon" @click="removeAvatarImage(i)">{{
                  icons.mdiClose
                }}</v-icon>
              </v-avatar>
            </v-col>
          </v-row>

          <v-switch
            v-model="carFleetData.active"
            inset
            :color="carFleetData.active ? 'success' : 'error'"
            :label="`${carFleetData.active ? 'Active' : 'Inactive'}`"
          ></v-switch>

          <v-alert dense text color="error" v-if="error">
            {{ error }}
          </v-alert>

          <v-btn color="primary" class="me-3" :loading="loading" type="submit">
            {{ id ? 'Update' : 'Add' }}
          </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetcarFleetData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCircle } from '@mdi/js'
import { find, each, reject, isEmpty, filter, includes, map } from 'lodash'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator, requiredLength } from '@core/utils/validation'
import useCarFleet from './useCarFleet'
import useAppConfig from '@/@core/@app-config/useAppConfig'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    id: {
      type: String | null,
      required: true,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    brandOptions: {
      type: Array,
      required: true,
    },
    featureOptions: {
      type: Array,
      required: true,
    },
    fuelOptions: {
      type: Array,
      required: true,
    },
    gearOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { country } = useAppConfig()
    const typeKey = country.value == 'UK' ? 'ltype' : 'markltype'
    const imgcarmobKey = country.value == 'UK' ? 'imgcarmob' : 'markimgcarmob'
    const fileRules = [v => !!v || 'File is required', v => (v && v.size > 0) || 'File is required']
    const valid = ref(false)
    const form = ref(null)
    const imageUrl = ref('')
    const sliderImagesUrl = ref([])
    const avatarImages = ref([])

    const { addCarFleet, carFleets, carFleetData, blankCarFleetData, error, loading, editCarFleet } = useCarFleet()

    const filterdBrandOptions = computed(() => {
      const type = country.value == 'UK' ? carFleetData.value.ltype : carFleetData.value.markltype
      if (type && type.length) {
        if (country.value == 'UK') {
          return filter(props.brandOptions, ({ ltype }) => ltype == type)
        } else {
          return filter(props.brandOptions, ({ markltype }) => markltype == type)
        }
      }
      return []
    })

    const validate = () => {
      form.value.validate()
    }

    const resetForm = async () => {
      await form.value.reset()
      carFleetData.value = JSON.parse(JSON.stringify(blankCarFleetData))
      imageUrl.value = ''
      sliderImagesUrl.value = []
    }

    const resetcarFleetData = () => {
      resetForm()
      error.value = null
      emit('reset-id')
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        if (props.id) {
          await editCarFleet(props.id)
          emit('car-fleet-updated', props.id)
        } else {
          await addCarFleet()
        }
        if (!error.value) {
          resetcarFleetData()
        }
      } else {
        validate()
      }
    }

    const onLandingImageChange = file => {
      if (country.value == 'UK') {
        carFleetData.value.imgcarmob = file
      } else {
        carFleetData.value.markimgcarmob = file
      }
      if (!file) {
        return
      }
      createImage(file)
    }

    const onSliderImageChange = files => {
      if (country.value == 'UK') {
        carFleetData.value.imgslider.push(...files)
      } else {
        carFleetData.value.markimgslider.push(...files)
      }
      if (!files.length) {
        return
      }
      createImages(files)
    }

    const onAvatarImageChange = files=>{
      
      carFleetData.value.avatarImages.push(...files)

      if(!files.length){
        return
      }
      createAvatarImages(files)
    }

    const createImage = file => {
      
      const reader = new FileReader()

      reader.onload = e => {
        imageUrl.value = e.target.result
      }
      reader.readAsDataURL(file)
    }

    const createImages = files => {
      each(files, file => {
        const reader = new FileReader()

        reader.onload = e => {
          sliderImagesUrl.value.push(e.target.result)
        }
        reader.readAsDataURL(file)
      })
    }

    const createAvatarImages = files => {
      each(files, file => {
        const reader = new FileReader()

        reader.onload = e => {
          avatarImages.value.push(e.target.result)
        }
        reader.readAsDataURL(file)
      })
      
    }

    const removeSliderImage = index => {
      if (country.value == 'UK') {
        carFleetData.value.imgslider.splice(index, 1)
      } else {
        carFleetData.value.markimgslider.splice(index, 1)
      }
      console.log(sliderImagesUrl.value)
      sliderImagesUrl.value.splice(index, 1)
      console.log(sliderImagesUrl.value,"******************88")
    }

    const removeAvatarImage = index => {
      if (country.value == 'UK') {
        carFleetData.value.imgslider.splice(index, 1)
      } else {
        carFleetData.value.avatarImages.splice(index, 1)
      }
      avatarImages.value.splice(index, 1)
    }

    const brandOptionChanged = item => {
      const brand = find(props.brandOptions, ['description', item])
      carFleetData.value.brand = brand.description
      carFleetData.value.brandactive = brand.active
      carFleetData.value.brandimg = brand.brandimg
    }

    const featureOptionChanged = items => {
      const features = filter(props.featureOptions, ({ description }) => includes(items, description))
      carFleetData.value.features = map(features, 'description')
      carFleetData.value.featuresicon = map(features, 'featureicon')
    }

    const carTypeChanged = () => {
      carFleetData.value.brand = ''
      carFleetData.value.brandactive = null
      carFleetData.value.brandimg = ''
    }

    watch(
      () => props.id,
      () => {
        if (props.id) {
          const carFleet = find(carFleets.value, ['id', props.id])
          let sliderImages = []
          let avatarImagesArr = []
          if (country.value == 'UK') {
            sliderImages = reject(carFleet.imgslider.split('|'), isEmpty)
            carFleetData.value.ltype = carFleet.ltype
            carFleetData.value.imgcarmob = carFleet.imgcarmob
            carFleetData.value.imgslider = [...sliderImages]
            imageUrl.value = carFleet.imgcarmob
          } else {
            sliderImages = reject(carFleet.markimgslider.split('|'), isEmpty)
            avatarImagesArr = reject(carFleet.avatarImages, isEmpty)
            carFleetData.value.markltype = carFleet.markltype
            carFleetData.value.markimgcarmob = carFleet.markimgcarmob
            carFleetData.value.markimgslider = [...sliderImages]
            carFleetData.value.avatarImages = [...avatarImagesArr]
            imageUrl.value = carFleet.markimgcarmob
          }
          carFleetData.value.brand = carFleet.brand
          carFleetData.value.brandactive = carFleet.brandactive
          carFleetData.value.brandimg = carFleet.brandimg
          carFleetData.value.class = carFleet.class
          carFleetData.value.features = carFleet.features
          carFleetData.value.featuresicon = carFleet.featuresicon
          carFleetData.value.fuel = carFleet.fuel
          carFleetData.value.gear = carFleet.gear
          carFleetData.value.lugguage = carFleet.lugguage
          carFleetData.value.seat = carFleet.seat
          carFleetData.value.active = carFleet.active

          sliderImagesUrl.value = [...sliderImages]
          avatarImages.value = [...avatarImagesArr]

        }
      },
      { immediate: true },
    )

    watch(
      () => props.isAddNewUserSidebarActive,
      () => {
        if (!props.isAddNewUserSidebarActive) {
          resetcarFleetData()
        }
      },
    )

    return {
      resetcarFleetData,
      form,
      onSubmit,
      carFleetData,
      valid,
      validate,
      error,
      fileRules,
      imageUrl,
      sliderImagesUrl,
      onLandingImageChange,
      onSliderImageChange,
      avatarImages,
      onAvatarImageChange,
      loading,
      removeSliderImage,
      removeAvatarImage,
      brandOptionChanged,
      featureOptionChanged,
      filterdBrandOptions,
      carTypeChanged,
      country,
      typeKey,
      imgcarmobKey,
      // validation
      validators: { required, emailValidator, requiredLength },
      icons: {
        mdiClose,
        mdiCircle,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.close-icon {
  position: absolute;
  cursor: pointer;
  right: 0px;
  top: -2px;
}
</style>
