<template>
  <v-hover v-slot="{ hover }">
    <v-card class="mx-auto car-fleet-card" max-width="344" outlined>
      <v-list-item two-line class="mt-2">
        <v-list-item-avatar class="" tile width="130" height="100">
          <v-img contain :src="image"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="mb-1"> {{ brandName }} {{ fleetClass }} </v-list-item-title>
          <v-list-item-subtitle class="last-modified d-flex mt-1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" x-small>{{ icons.mdiCalendarBlankOutline }}</v-icon>
              </template>
              <span>Last Modified</span>
            </v-tooltip>
            <div class="ml-1">{{ updatedOn }}</div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <span class="primary--text" @click="handleSelectFaq(id)" style="cursor: pointer"> FAQ </span>
      </v-card-actions>
      <v-card-actions class="mt-n5">
        <span class="primary--text"> Details </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showDetail = !showDetail">
          <v-icon>{{ showDetail ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
        </v-btn>
      </v-card-actions>
      <!-- <v-btn color="primary" class="ml-3" size="sm" @click="detailDialog = true">
        <v-icon size="17" class="me-1">
          {{ icons.mdiPlus }}
        </v-icon>
        <span>FAQ</span>
      </v-btn> -->

      <v-dialog v-model="detailDialog" max-width="850">
        <v-card>
          <v-card-title class="d-flex">
            <div class="text-h5 mr-auto">FAQ</div>
            <v-btn icon>
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-tabs v-model="detailTab" background-color="transparent" color="basil" grow>
              <v-tab> FAQ </v-tab>
              <v-tab> Car Info </v-tab>
            </v-tabs>

            <v-tabs-items v-model="detailTab">
              <v-tab-item class="mt-4">
                <v-card-text>
                  <v-card>
                    <v-btn color="primary" class="mb-4 ml-3 mr-3" @click="addFaq = true">
                      <v-icon size="17" class="me-1">
                        {{ icons.mdiPlus }}
                      </v-icon>
                      <span>Add</span>
                    </v-btn>
                    <v-data-table :headers="faqColumnsHeaders" :items="faqData" hide-default-footer>
                      <template v-slot:item.actions="{ item }">
                        <v-btn color="primary" text x-small @click="handleFaqEdit = true"> Edit </v-btn>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <card-info/>
                 </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
        <v-dialog v-model="addFaq" max-width="450">
          <v-card>
            <v-card-title class="d-flex">
              <div class="text-h5 mr-auto">ADD FAQ</div>
              <v-btn icon>
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-form ref="form" v-model="valid" @submit.prevent>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-textarea
                      v-model="faq.question"
                        label="Qustion *"
                        outlined
                        dense
                        placeholder="Qustion *"
                        hide-details="auto"
                        class="mb-6"
                        
                      ></v-textarea>
                      <v-textarea
                      v-model="faq.answer"
                        label="Answer *"
                        outlined
                        dense
                        placeholder="Answer *"
                        hide-details="auto"
                        class="mb-6"
                        
                      ></v-textarea>
                      <v-btn color="primary" class="me-3" @click="handleFaq()"> Add </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-dialog>
      <v-expand-transition>
        <div v-show="showDetail">
          <v-divider></v-divider>

          <v-carousel hide-delimiter-background hide-delimiters height="150" class="pt-4">
            <v-carousel-item
              v-for="(img, i) in sliderImages"
              :key="i"
              reverse-transition="fade-transition"
              transition="fade-transition"
              class="mx-8"
            >
              <v-img :src="img" contain> </v-img>
            </v-carousel-item>
          </v-carousel>

          <v-card-text class="">
            <span v-html="extraDetail"></span>
          </v-card-text>
        </div>
      </v-expand-transition>
      <div>
        <div class="status-icon">
          <v-icon x-small :color="isActive ? 'success' : 'error'">{{ icons.mdiCircle }}</v-icon>
          <span class="ml-2 brand-type">{{ type }}</span>
        </div>
        <div class="notification" v-if="notify">
          <v-chip class="v-chip-light-bg success--text" small> Updated </v-chip>
        </div>
        <v-icon class="ml-4 edit-icon" small @click="$emit('edit-carfleet', id)">{{ icons.mdiPencil }}</v-icon>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiCircle, mdiPencil, mdiCalendarBlankOutline, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import CardInfo from "./CarInfo.vue"
import useCarFleetList from './useCarFleet'

export default {
  components: {
    CardInfo
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String | undefined,
      required: true,
    },
    image: {
      type: String | undefined,
      required: true,
    },
    sliderImages: {
      type: Array | undefined,
      required: true,
    },
    brandName: {
      type: String,
      required: true,
    },
    updatedOn: {
      type: String,
      required: true,
    },
    fleetClass: {
      type: String,
      required: true,
    },
    fuel: {
      type: String,
      required: true,
    },
    gear: {
      type: String,
      required: true,
    },
    luggage: {
      type: Number,
      required: true,
    },
    seat: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    showNotification: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const showDetail = ref(false)
    const notify = ref(false)
    const detailDialog = ref(false)
    const addFaq = ref(false)
    const faqData = ref(null);
    const faq = ref(JSON.parse(JSON.stringify({
      question: "",
      answer: ""
    })))
    const faqColumnsHeaders = [
      {
        text: 'ID',
        value: 'order_id',
      },
      { text: 'Question', value: 'question' },
      { text: 'Answer', value: 'answer' },
      { text: 'Actions', value: 'actions' },
    ];
    const faqEdit = false;
    const detailTab = ref(false)
    const { carFleetData, fetchCarFleet, editCarFleet } = useCarFleetList()
    const docId = ref(null);
    const extraDetail = computed(
      () => `${props.seat} people &#8226; ${props.luggage} bags &#8226; ${props.gear} &#8226; ${props.fuel}`,
    )

    watch(
      () => props.showNotification,
      () => {
        if (props.showNotification) {
          notify.value = true
          setTimeout(() => {
            notify.value = false
          }, 3000)
        }
      },
    )
    let arr = []
    const handleFaq = async () => {
      
      let data = {
        id: Math.random().toString(36).slice(2),
        question: faq.value.question,
        answer: faq.value.answer
      }
      arr.push(data)

     carFleetData.value.faq = arr;
      faq.value = {
        question: "",
        answer: ""
      }
      addFaq.value = false;

      await editCarFleet(docId); 

     
    }

    const handleSelectFaq = async (id)=>{
      detailDialog.value = true;
      docId.value = id;
      console.log(docId.value,"***************")
    }

    return {
      showDetail,
      notify,
      extraDetail,
      icons: {
        mdiCircle,
        mdiPencil,
        mdiCalendarBlankOutline,
        mdiChevronUp,
        mdiChevronDown,
      },
      detailDialog,
      handleFaq,
      addFaq,
      faqColumnsHeaders,
      faqData,
      detailTab,
      faq,
      handleSelectFaq
    }
  },
}
</script>

<style scoped lang="scss">
.car-fleet-card {
  .status-icon {
    position: absolute;
    cursor: pointer;
    left: 10px;
    top: 5px;
  }

  .edit-icon {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 5px;
    color: rgb(88, 88, 88);
  }

  .notification {
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 5px;
    color: rgb(88, 88, 88);
  }

  .brand-type {
    font-size: 00.8rem;
  }

  .last-modified {
    font-size: 0.7rem;
  }

  .card-actions {
    position: absolute;
    cursor: pointer;
    // left: 10px;
    bottom: 0px;
  }

  .last-modified {
    font-size: 0.7rem;
  }
}
</style>
